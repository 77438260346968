<template>
  <div></div>
</template>


<script>
export default {
  beforeCreate() {
    const { params, query } = this.$route
    const { path } = params
    this.$router.replace({ path: '/' + path, query })
    // 报错， uncaught router error, 关闭浏览器重新打开
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
